import PropTypes from "prop-types"
import React from "react"
import BulmaColumns from "./BulmaColumns"

export default function HorizontalThreeColumns(props) {
  return (
    <BulmaColumns className={props.className} style={props.style}>
      <div className="column is-2" />
      {props.leftColWidth !== 0 && (
        <div className={`column is-${props.leftColWidth}`}>
          {props.leftColumn}
        </div>
      )}

      <div className="column is-1" />

      <div className={`column`}>{props.middleColumn}</div>

      <div className="column is-1" />
      {props.rightColWidth !== 0 && (
        <div className={`column is-${props.rightColWidth}`}>
          {props.rightColumn}
        </div>
      )}
      <div className="column is-2" />
    </BulmaColumns>
  )
}

HorizontalThreeColumns.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  leftColWidth: PropTypes.number.isRequired,
  rightColWidth: PropTypes.number.isRequired,
  leftColumn: PropTypes.element,
  rightColumn: PropTypes.element,
  middleColumn: PropTypes.element,
}
