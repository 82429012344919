import React, { useRef, useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Stars from "./Stars"
import Button from "@bit/azheng.joshua-tree.button"
import classnames from "classnames"
import Slider from "react-slick"

const AllSocialReviews = ({
  reviews,
  language,
  heading,
  buttonHref,
  buttonText,
  isColumn,
  colorBack,
  animation,
  quoteSlides
}) => {
  const animate = animation ? "fade-up" : ""

  const wrapperClasses = classnames({
    "home-section": heading,
    "ratings-review top-down": true,
    "color-back": colorBack
  })

  const reviewsContainer = classnames({
    "all-reviews-container-flex": isColumn,
    "all-reviews-container-grid": !isColumn
  })

  const data = useStaticQuery(graphql`
    query SocialQuery {
      rentonJson {
        data {
          code
          platformId
          platform
          rating
          totalReviews
          url
        }
      }
      puyallupJson {
        data {
          code
          platform
          platformId
          rating
          totalReviews
          url
        }
      }
      federalWayJson {
        data {
          code
          platform
          platformId
          totalReviews
          rating
          url
        }
      }
      auburnJson {
        data {
          platform
          code
          platformId
          totalReviews
          rating
          url
        }
      }
      mapleValleyJson {
        data {
          code
          platform
          platformId
          rating
          totalReviews
          url
        }
      }
      mapleValleyJson {
        data {
          code
          platform
          platformId
          rating
          totalReviews
          url
        }
      }
      fbAuburnJson {
        data {
          platform
          platformId
          rating
          totalReviews
          url
          code
        }
      }
      fbFederalWayJson {
        data {
          platform
          code
          platformId
          rating
          totalReviews
          url
        }
      }
      fbRentonJson {
        data {
          platform
          code
          platformId
          rating
          totalReviews
          url
        }
      }
    }
  `)

  let federal = data.federalWayJson.data
  let maple = data.mapleValleyJson.data
  let renton = data.rentonJson.data
  let auburn = data.auburnJson.data
  let puyallup = data.puyallupJson.data

  let fbAuburn = data.fbAuburnJson.data
  let fbFederal = data.fbFederalWayJson.data
  let fbRenton = data.fbRentonJson.data

  let slider2Dots = []

  auburn.url =
    "https://www.google.com/search?q=pacific+northwest+oral+surgeons+309+Second+Street+SE+Auburn%2C+WA+98002&rlz=1C5CHFA_enUS910US910&ei=yw1_YLKcAoySsAWxobLQCw&oq=pacific+northwest+oral+surgeons+309+Second+Street+SE+Auburn%2C+WA+98002&gs_lcp=Cgdnd3Mtd2l6EAM6BwgAEEcQsAM6CQgAEMkDEBYQHjoGCAAQFhAeUPUGWKsIYNEKaAFwAXgAgAFPiAHgAZIBATOYAQCgAQGgAQKqAQdnd3Mtd2l6yAEIwAEB&sclient=gws-wiz&ved=0ahUKEwjyrd2Bq43wAhUMCawKHbGQDLoQ4dUDCA8&uact=5#lrd=0x5490587083fa445f:0x2df8c146cb551c60,1"
  federal.url =
    "https://www.google.com/search?q=pacific+northwest+oral+surgeons+2345+SW+320th+St+Federal+Way%2C+WA+98023&rlz=1C5CHFA_enUS910US910&ei=zQ1_YKXNHczQsAWPu5nQBg&oq=pacific+northwest+oral+surgeons+2345+SW+320th+St+Federal+Way%2C+WA+98023&gs_lcp=Cgdnd3Mtd2l6EANQ-n5Y-n5gyIABaABwAXgAgAF2iAHOAZIBAzEuMZgBAKABAqABAaoBB2d3cy13aXrAAQE&sclient=gws-wiz&ved=0ahUKEwjl5_KCq43wAhVMKKwKHY9dBmoQ4dUDCA8&uact=5#lrd=0x5490571e68d010cf:0x391a2fdf3db0d34e,1"
  renton.url =
    "https://www.google.com/search?q=pacific+northwest+oral+surgeons+601+South+Carr+Road+Renton%2C+WA+98055&rlz=1C5CHFA_enUS910US910&ei=3w1_YIaCHcPisAX64paQDw&oq=pacific+northwest+oral+surgeons+601+South+Carr+Road+Renton%2C+WA+98055&gs_lcp=Cgdnd3Mtd2l6EANQlnZYlnZgu3doAHABeACAAXCIAcMBkgEDMS4xmAEAoAECoAEBqgEHZ3dzLXdpesABAQ&sclient=gws-wiz&ved=0ahUKEwjG7byLq43wAhVDMawKHXqxBfIQ4dUDCA8&uact=5#lrd=0x54905d05a2735c69:0x48f5a7bec3e864a8,1"
  maple.url =
    "https://www.google.com/search?q=pacific+northwest+oral+surgeons+26808+Maple+Valley+Black+Diamond+Rd+SE+Maple+Valley%2C+WA+98038&rlz=1C5CHFA_enUS910US910&ei=8A1_YNPrHoaQsAW6yaDoAg&oq=pacific+northwest+oral+surgeons+26808+Maple+Valley+Black+Diamond+Rd+SE+Maple+Valley%2C+WA+98038&gs_lcp=Cgdnd3Mtd2l6EAM6BwgAEEcQsANQvnlYvnlg5HpoAXABeACAAYgBiAHpAZIBAzEuMZgBAKABAqABAaoBB2d3cy13aXrIAQjAAQE&sclient=gws-wiz&ved=0ahUKEwjTo8yTq43wAhUGCKwKHbokCC0Q4dUDCA8&uact=5#lrd=0x54908aa7325c5b03:0xda872a3f2724cb5,1"
  puyallup.url =
    "https://www.google.com/search?q=pacific+northwest+oral+surgeons+8012+112th+St+CT+E%2C+Ste+%23260+Puyallup%2C+WA+98373&rlz=1C5CHFA_enUS910US910&ei=zQ1_YKXNHczQsAWPu5nQBg&oq=pacific+northwest+oral+surgeons+8012+112th+St+CT+E%2C+Ste+%23260+Puyallup%2C+WA+98373&gs_lcp=Cgdnd3Mtd2l6EANQtaYBWLWmAWCYqAFoAHABeACAAW6IAbcBkgEDMS4xmAEAoAECoAEBqgEHZ3dzLXdpesABAQ&sclient=gws-wiz&ved=0ahUKEwjl5_KCq43wAhVMKKwKHY9dBmoQ4dUDCA8&uact=5#lrd=0x5490fd63a2c6391b:0x98ff66c7ddfee700,1"

  if (!reviews) {
    reviews = [
      { name: "Federal Way", ...federal },
      { name: "Auburn", ...auburn },
      { name: "Renton", ...renton },
      { name: "Maple Valley", ...maple },
      { name: "Puyallup", ...puyallup },
      { name: "Federal Way", ...fbFederal },
      { name: "Auburn", ...fbAuburn },
      { name: "Renton", ...fbRenton }
    ]
  }

  const [nav1, setNav1] = useState()
  const [nav2, setNav2] = useState()
  const slider1 = useRef(null)
  const slider2 = useRef(null)

  useEffect(() => {
    setNav1(slider1.current)
    setNav2(slider2.current)
  }, [])

  const [imageIndex, setImageIndex] = useState(0)

  const slider1Settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: 0,
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 8000,
    cssEase: "ease",
    focusOnSelect: true,
    arrows: false,
    swipeToSlide: true,
    beforeChange: (current, next) => setImageIndex(next),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  const slider2Settings = {
    arrows: true,
    slidesToShow: 1,
    dots: true,
    pauseOnDotsHover: true,
    pauseOnHover: true
  }

  const quoteSlideNum = quoteSlides.length

  for (let i = 1; i <= quoteSlideNum; i++) {
    slider2Dots.push(<div key={i}></div>)
  }

  if (quoteSlideNum === 3 || quoteSlideNum === 2) {
    quoteSlides = quoteSlides.concat(quoteSlides)
    slider2Dots = slider2Dots.slice(0, 3)
  }

  return (
    <section className={wrapperClasses}>
      <div className="container" data-aos={animate} data-aos-duration="1200">
        {heading && (
          <div className="columns">
            <div className="column is-2"></div>
            <div className="column is-20">
              <h2 className="has-text-centered-tablet h3-style">{heading}</h2>
            </div>
            <div className="column is-2"></div>
          </div>
        )}

        {quoteSlides && (
          <div style={{ padding: "40px 0 80px" }}>
            <div>
              <Slider {...slider1Settings} asNavFor={nav2} ref={slider1}>
                {quoteSlides.map((slide, i) => (
                  <QuoteSlide
                    key={i}
                    quoteText={slide.slide.quoteText}
                    quoteName={slide.slide.quoteName}
                    className={
                      i === imageIndex
                        ? "activeSlide quote-slide-box"
                        : "quote-slide-box"
                    }
                  />
                ))}
              </Slider>
              <div
                style={{ display: "block", width: 200, margin: "50px auto 0" }}>
                <Slider
                  className="bottom-quote-slider"
                  {...slider2Settings}
                  asNavFor={nav1}
                  ref={slider2}>
                  {slider2Dots}
                </Slider>
              </div>
            </div>
          </div>
        )}

        <div
          className="rating-reviews home-reviews columns is-centered iconless big-rating"
          style={{
            maxWidth: "1100px",
            marginLeft: "auto",
            marginRight: "auto"
          }}>
          {/* <div className="column is-2"></div> */}
          <div
            className={reviewsContainer}
            style={{ flexDirection: isColumn ? "column" : "row" }}>
            {reviews &&
              reviews.map((review, i) => (
                <div
                  className="featured-social"
                  id={`social-review-block-${i + 1}`}>
                  <div className="office-platform-container">
                    <span
                      role="presentation"
                      style={{
                        fontSize: "0.875rem",
                        lineHeight: "1.71",
                        color: "#6d6e6f",
                        display: "block",
                        marginBottom: review.name ? "-4px" : "20px",
                        fontWeight: "bold",
                        fontFamily: "Montserrat"
                      }}>
                      {review.name ? review.name + " Office" : ""}
                    </span>
                    <a
                      aria-label="open link in new browser tab"
                      title="View all of our reviews"
                      className="link-wrapper"
                      href={review.url}
                      target="_blank"
                      rel="noopener noreferrer">
                      <h3>
                        {review.platform === "googleMyBusiness"
                          ? "Google"
                          : "Facebook"}
                      </h3>
                      <div className="total-reviews">
                        ({review.totalReviews})
                      </div>
                    </a>
                  </div>
                  <div>
                    <a
                      aria-label="open link in new browser tab"
                      title="View all of our reviews"
                      href={review.url}
                      className="reviews-link"
                      target="_blank">
                      <div className="review-icons">
                        <Stars rating={review.rating} />
                      </div>
                    </a>
                    <a
                      aria-label="open link in new browser tab"
                      title="View all of our reviews"
                      href={review.url}
                      className="reviews-link"
                      target="_blank">
                      <span className="reviews-number">
                        {review.rating === 5 ? "5.0" : review.rating}
                      </span>
                    </a>
                  </div>
                </div>
              ))}
          </div>
          {/* <div className="column is-2"></div> */}
        </div>
      </div>

      {buttonHref && (
        <div className="columns has-text-centered">
          <div className="column">
            <Button contained buttonText={buttonText} href={buttonHref} />
          </div>
        </div>
      )}
    </section>
  )
}

function QuoteSlide(props) {
  return (
    <div key={props.key} className={props.className}>
      <div className="quote-slide-quote">
        <p>{props.quoteText}</p>
      </div>
      <div className="quote-slide-name">
        <h5>{props.quoteName}</h5>
      </div>
    </div>
  )
}

export default AllSocialReviews
